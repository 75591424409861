<template>
  <!-- 票据管理详情 -->
  <div class="page-info-content">
    <h3 class="page-subtitle-shade">
      基础信息
    </h3>
    <div class="flex-row">
      <div class="image-upload-box">
        <ImageUpload class="image-upload" explain="上传票据正面(限5M)" :edit-img-url="detailObj.billFileUrl" :edit-img-name="detailObj.billFileName" :upload-disabled="true" :download="true" />
      </div>
      <DetailsPage
        class="interval"
        details-title=""
        :detail-obj="detailObj"
        :details-item-arr="detailItemArr"
        :column="2"
        border
      />
    </div>

    <DetailsPage
      class="interval"
      details-title="交易合同"
      :detail-obj="detailObj"
      :details-item-arr="contractDetailItemArr"
      :column="3"
      border
    />
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage2.vue'
import ImageUpload from '../components/ImageUpload.vue'
import { operateGetBillDesc } from '@/api/piaoju.js'
export default {
  components: { DetailsPage, ImageUpload },
  data() {
    return {
      detailObj: {},
      detailItemArr: [
        { label: '票据号码', value: 'billNo' },
        { label: '票面金额', money: 'faceAmount', rightWords: '元' },
        { label: '出票日期', value: 'drawerDate' },
        { label: '到期日期', value: 'expireDate' },
        { label: '出票人', value: 'drawer' },
        { label: '承兑人', value: 'acceptor' },
        { label: '收款人', value: 'payee' },
        { label: '票据类型', value: 'billType', child: this.$store.getters.getDictionaryItem('BILL_TYPE') },
        { label: '现有持票人', value: 'holder' }
      ],
      contractDetailItemArr: [
        { label: '合同编号', value: 'contractNo' },
        { label: '合同文件', pdfName: 'contractFileName', pdfUrl: 'contractFileUrl' },
        { label: '发票文件', pdfName: 'invoiceFileName', pdfUrl: 'invoiceFileUrl' }
      ]
    }
  },
  mounted() {
    if (this.$route.query.id) {
      operateGetBillDesc(this.$route.query.id, res => {
        this.detailObj = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.image-upload-box {
  width: 33.33%;
}
.image-upload {
  width: 468px;
  height: 260px;
  background-color: #FCFBFC;
}
.flex-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 56px;
}
.interval {
  flex: 1;
}
/deep/ .el-button {
  background: transparent;
  border: none;
  color: $mainBlue;
  padding: 2px 10px;
}
</style>
